import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

class Footer extends Component {

	render() {

		const { context } = this.props;

		return(

			<footer>
				<img alt="" className="logo-footer" width="140" height="50" src="./images/logo/logo-dark@2x.png" />
				<div className="links">
					<ul>
						{context.translate('footer.links').map(function(elem, index) {
							return <li key={index}><NavLink exact to={elem.link} activeClassName="active">{elem.label}</NavLink></li>
						})}
						<li>
							<a href={context.translate('social.facebook')} rel="noopener noreferrer" target="_blank">
								<img alt="" src="./images/social-networks/fb-icon.svg" height="18" align="absmiddle" />
							</a>
						</li>
						<li>
							<a href={context.translate('social.instagram')} rel="noopener noreferrer" target="_blank">
								<img alt="" src="./images/social-networks/ig-icon.svg" height="18" align="absmiddle" />
							</a>
						</li>
					</ul>
				</div>
				<div className="contact">{context.translate('footer.contact')}</div>
				<div className="legal">{context.translate('footer.legal')}</div>
			</footer>
		)
	}
}

export default Footer;
