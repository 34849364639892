import React from 'react';

const Instagram = (props) => (
	<div className="instagram">
		<div className="instagram__follow-us"><img alt="Instagram" src="./images/social-networks/ig-icon.svg" height="24" align="absmiddle" /> Follow Us</div>
		<iframe className="instagram__iframe" title="instagram" src="instagram.html"></iframe>
		<div className="instagram__account">@Favacatering</div>
	</div>
);

export default Instagram;

