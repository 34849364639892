import React, { Component } from 'react';
import TrackVisibility from 'react-on-screen';

import Container from '../components/Container.js'
import Content from '../components/Content.js'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Card from '../components/Card.js'
import Hero from '../components/Hero.js'
import Instagram from '../components/Instagram.js'

import { MyContext } from '../Context'

class WeAre extends Component {

	render() {
		return (
			<MyContext.Consumer>
				{(context) => (
					<React.Fragment>
						<Container background="weare">
							<Header context={context} />
							<Hero data={context.translate('we-are.hero')}/>
							<Content>
								<div className="we-are-cards">
								{context.translate('we-are.cards').map(function(elem, index) {
									return (
										<TrackVisibility partialVisibility key={index}>
											<Card modifiers="card--image-shadow" data={elem} index={index}  />
										</TrackVisibility>
									)
								})}
								</div>
							</Content>
							<Instagram />
						</Container>
						<Footer context={context} />
					</React.Fragment>
				)}
			</MyContext.Consumer>
		)
	}
}

export default WeAre;