import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import TrackVisibility from 'react-on-screen';

import Container from '../components/Container.js'
import Content from '../components/Content.js'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Card from '../components/Card.js'
import Instagram from '../components/Instagram.js'

import { MyContext } from '../Context'

class Homepage extends Component {

	render() {

		return (
			<MyContext.Consumer>
				{(context) => (
					<React.Fragment>
						<Container background="homepage" theme="light">
							<Header context={context} theme="light" />
							<Content>
								<h2 className="white">{context.translate('homepage.title')}</h2>
								<h1 dangerouslySetInnerHTML={ {__html: context.translate('homepage.subtitle')} } />
								<div className="homepage-scroll">{context.translate('homepage.about-us')}</div>
							</Content>
						</Container>

						<Container>
							<div className="homepage-cards">
								{context.translate('homepage.cards').map(function(elem, index) {
									return (
										<TrackVisibility partialVisibility key={index}>
											<Card modifiers="card--image-shadow" data={elem} index={index}  />
										</TrackVisibility>
									)
								})}
							</div>

							<div className="homepage-quote">
								<h1>{context.translate('homepage.quote.title')}</h1>
								<NavLink className="button" exact to={context.translate('homepage.quote.link.target')}>{context.translate('homepage.quote.link.title')}</NavLink>
							</div>

							<Instagram />

						</Container>

						<Footer context={context} />
					</React.Fragment>
				)}
			</MyContext.Consumer>
		)
	}
}

export default Homepage;