import React, { Component } from 'react'

import englishTranslationsArg from './translations/arg/english.json'
import spanishTranslationsArg from './translations/arg/spanish.json'

import englishTranslationsUsa from './translations/usa/english.json'
import spanishTranslationsUsa from './translations/usa/spanish.json'

export const MyContext = React.createContext()

export class MyProvider extends Component {
	state = {
		'site': 'arg', // arg | usa
		language: 'spanish', // spanish | english
		'menuOpened': false
	}

	changeLanguage = (e) => {
		const newLanguage = e.target.dataset.language;

		this.setState({
			language: newLanguage
		})
	}

	getKey = (obj, desc) => {
		var arr = desc.split('.');
		while (arr.length && (obj = obj[arr.shift()]));
		return obj;
	}

	translate = (key) => {
		var translations

		if (this.state.site === 'arg') {
			translations = englishTranslationsArg

			if (this.state.language === 'spanish') {
				translations = spanishTranslationsArg
			}
		} else {
			translations = englishTranslationsUsa

			if (this.state.language === 'spanish') {
				translations = spanishTranslationsUsa
			}
		}

		var value = this.getKey(translations, key);
		return value;
	}

	render() {
		return (
			<MyContext.Provider value={{
				state: this.state,
				changeLanguage: this.changeLanguage,
				translate: this.translate
			}}>
				{this.props.children}
			</MyContext.Provider>
		)
	}
}