import React, { Component } from 'react';

class Content extends Component {

	render() {

		const { align = 'center' } = this.props;

		return(
			<div className={`content clearfix ${align}`}>
				{this.props.children}
			</div>
		)

	}
}

export default Content;
