import React, { Component } from 'react';

import Container from '../components/Container.js'
import Content from '../components/Content.js'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'

import { MyContext } from '../Context'

class Contact extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)

		this.state = {
			name: '',
			email: '',
			telephone: '',
			attendees: '',
			place: '',
			date: '',
			type: '',
			message: '',
			errors: {},
			submitted: false
		};
	}

	handleInputChange(event) {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			[name]: value
		});
	}

	valid () {
		const { name, email, telephone } = this.state

		const errors = {
			name: name.length === 0,
			email: email.length === 0,
			telephone: telephone.length === 0
		}

		this.setState({
			'errors': errors
		});

		return Object.keys(errors).every((k) => !errors[k])

	}

	handleSubmit(event) {
		event.preventDefault();

		var formData = new FormData();
		formData.append('nombre_apellido', this.state.name)
		formData.append('email', this.state.email)
		formData.append('telefono', this.state.telephone)
		formData.append('cant_invitados', this.state.attendees)
		formData.append('lugar', this.state.place)
		formData.append('fecha', this.state.date)
		formData.append('tipo_evento', this.state.type)
		formData.append('comentarios', this.state.message)

		if (this.valid()) {
		
			this.setState({
				'submitted': true
			});

			fetch('/contacto.php', {
				method: 'POST',
				body: formData
			})
		}
	}

	render() {
		return (
			<MyContext.Consumer>
				{(context) => (
					<React.Fragment>
						<Container background="contact" theme="light">
							<Header context={context} theme="light" />
							<Content>
								<h2 className="white">{context.translate('contact.title')}</h2>
								<h1 dangerouslySetInnerHTML={ {__html: context.translate('contact.subtitle')} } />
							</Content>
						</Container>

						<div className="contact-options">
							<div className="row">
								<div className="column-2">
									<h2>{context.translate('contact.title-quote')}</h2>
									{(this.state.submitted) ? (
									<p>{context.translate('contact.form.submitted')}</p>
									) : (
									<form className="form" onSubmit={this.handleSubmit}>
										<Row>
											<Field label={context.translate('contact.form.name')} name="name" change={this.handleInputChange} error={this.state.errors.name} />
										</Row>

										<Row>
											<Field label={context.translate('contact.form.email')} name="email" change={this.handleInputChange} error={this.state.errors.email} />
										</Row>

										<Row>
											<Field label={context.translate('contact.form.telephone')} name="telephone" change={this.handleInputChange} error={this.state.errors.telephone} />
										</Row>

										<Row>
											<Field label={context.translate('contact.form.attendees')} name="attendees" change={this.handleInputChange} />
											<Field label={context.translate('contact.form.place')} name="place" change={this.handleInputChange} />
										</Row>

										<Row>
											<Field label={context.translate('contact.form.date')} name="date" change={this.handleInputChange} />
											<Field label={context.translate('contact.form.type.title')} name="type" change={this.handleInputChange} type="select" options={context.translate('contact.form.type.options')} />
										</Row>

										<Row>
											<Field label={context.translate('contact.form.message')} name="message" change={this.handleInputChange} type="textarea" />
										</Row>

										<Row>
											<button className="button button--block form__button">{context.translate('contact.form.button')}</button>
										</Row>
									</form>
									)}
								</div>
								<div className="column-2">
									<h2>{context.translate('contact.title-where-are-we')}</h2>
									<GoogleMap map={context.translate('contact.map')} />
									<div className="line">{context.translate('contact.address')}</div>
									<div className="line">{context.translate('contact.city')}</div>
									<div className="line">{context.translate('contact.telephone')}</div>
									<div className="line">{context.translate('contact.email')}</div>
									<h3>{context.translate('contact.work-with-us')}</h3>
									<a href="mailto:rrhh@favacatering.com.ar" className="button button--block">rrhh@favacatering.com.ar</a>
								</div>
							</div>
						</div>

						<Footer context={context} />
					</React.Fragment>
				)}
			</MyContext.Consumer>
		)
	}
}

const Row = (props) => (
	<div className="form__row">
		{props.children}
	</div>
);

const Field = (props) => (
	<div className="form__field">
		<label>{props.label}</label>
		{(() => {
		switch(props.type) {
			case 'textarea':
				return <textarea name={props.name} onChange={props.change} />
			case 'select':
				return (
					<select name={props.name} onChange={props.change}>
					{
						props.options.map((elem, index) => {
							return <option value={elem.id} key={index}>{elem.title}</option>
						})	
					}
					</select>
				)
			default:
				return <input className={(props.error === true) ? 'error' : ''} type="text" name={props.name} onChange={props.change} />

		}
		})()}
	</div>
);

const GoogleMap = (props) => (
	<iframe className="map" title="map" src={props.map} width="100%" height="450" frameBorder="0" allowFullScreen></iframe>
);

export default Contact;