import React, { Component } from 'react';
import Slider from 'react-slick';

class Slideshow extends Component {

	constructor(props) {
		super(props);
		this.prev = this.prev.bind(this);
		this.next = this.next.bind(this);
	}

	prev() {
		this.slider.slickPrev();
	}

	next() {
		this.slider.slickNext();
	}

	render() {

		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			variableWidth: true,
			autoplay: true,
			arrows: false,
		};

		return (
			<React.Fragment>
				<Slider {...settings} ref={c => (this.slider = c)}>
					<div><img alt="" src="/images/sections/events/slideshow/1.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/2.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/3.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/4.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/5.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/6.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/7.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/8.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/9.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/10.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/11.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/12.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/13.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/14.jpg" /></div>
					<div><img alt="" src="/images/sections/events/slideshow/15.jpg" /></div>
				</Slider>
				<div className="arrows">
					<PrevArrow onClick={this.prev} />
					<NextArrow onClick={this.next} />
				</div>
			</React.Fragment>
		);
	}
}

const PrevArrow = function(props) {
	return (
		<div {...props}>
			<img alt="" src="./images/sections/events/slideshow/arrow-left.png" />
		</div>
	);
};

const NextArrow = function(props) {
	return (
		<div {...props}>
			<img alt="" src="./images/sections/events/slideshow/arrow-right.png" />
		</div>
	);
};

export default Slideshow;