import React from 'react'
import { Route } from 'react-router-dom'

import Homepage from './pages/Homepage.js'
import WeAre from './pages/WeAre.js'
import Events from './pages/Events.js'
import OurMenu from './pages/OurMenu.js'
import Venues from './pages/Venues.js'
import Contact from './pages/Contact.js'

import './styles/main.scss'

const Main = () => {
	return (
		<React.Fragment>
			<Route path="/" exact strict component={Homepage}/>
			<Route path="/(we-are|somos)" exact strict component={WeAre}/>
			<Route path="/(events|eventos)" exact strict component={Events}/>
			<Route path="/(our-menu|menu)" exact strict component={OurMenu}/>
			<Route path="/(venues|salones)" exact strict component={Venues}/>
			<Route path="/(contact|contacto)" exact strict component={Contact}/>
		</React.Fragment>
	)
}

export default Main