import React, { Component } from 'react';

class Hero extends Component {

	render() {

		const { data } = this.props;

		return(
			<div className="hero clearfix">
				<img alt="" className="hero__image" src={`./images/sections/${data.image}`} />
				<div className="text">
					<div className="hero__title">{data.title}</div>
					<div className="hero__subtitle">{data.subtitle}</div>
					<div className="hero__content">{data.content}</div>
				</div>
			</div>
		)

	}
}

export default Hero;
