import React from 'react'
import ReactDOM from 'react-dom'
import createHistory from 'history/createBrowserHistory';
import { Router } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'
import { MyProvider } from './Context'

import Main from './Main'

import './styles/index.css'

const history = createHistory();

history.listen((location, action) => {
	window.scrollTo(0, 0)
});

const App = () => {
	return (
		<MyProvider>
			<Router history={history}>
				<Main />
			</Router>
		</MyProvider>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))

registerServiceWorker()