import React, { Component } from 'react';

import Container from '../components/Container.js'
import Content from '../components/Content.js'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'

import { MyContext } from '../Context'

class Venues extends Component {

	chunkArray(myArray, chunk_size) {
		var index = 0;
		var arrayLength = myArray.length;
		var tempArray = [];
		var myChunk;

		for (index = 0; index < arrayLength; index += chunk_size) {
			myChunk = myArray.slice(index, index+chunk_size);
			tempArray.push(myChunk);
		}

		return tempArray;
	}
	
	render() {

		return (
			<MyContext.Consumer>
				{(context) => (
					<React.Fragment>
						<Container background="venues" theme="light">
							<Header context={context} theme="light" />
							<Content>
								<h2 className="white">Salones</h2>
								<Title />
							</Content>
						</Container>
						<Content>
							<div className="row"> 
								<div className="column">
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.larural.com.ar/"><img alt="" src="./images/sections/venues/la-rural.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.tattersall.com.ar/"><img alt="" src="./images/sections/venues/tattersall.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.palaciosanssouci.com/"><img alt="" src="./images/sections/venues/palacio-sans-souci.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://laherenciaeventos.com/esp/"><img alt="" src="./images/sections/venues/la-herencia.jpg" /></a>
								</div>
							</div>
							<div className="row">
								<div className="column">
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.rincondepilar.com/site.html"><img alt="" src="./images/sections/venues/rincon-de-pilar.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.espaciopilar.com.ar/"><img alt="" src="./images/sections/venues/espacio-pilar.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="https://www.astillerosmilberg.com/"><img alt="" src="./images/sections/venues/astilleros.jpg" /></a>
									<a className="venue-logo" rel="noopener noreferrer" target="_blank" href="http://www.santaelenaeventos.com.ar/"><img alt="" src="./images/sections/venues/estancia-santa-elena.jpg" /></a>
								</div>
							</div>
						</Content>
						<Content>
							<div className="venues-more">
								<h2>Además</h2>
								<div className="row">
									{
										this.chunkArray(context.translate('venues.more'), 7).map((column, key) => {
											return <Column venues={column} key={key} />
										})
									}
								</div>
							</div>
						</Content>
						<Footer context={context} />
					</React.Fragment>
				)}
			</MyContext.Consumer>
		)
	}
}

const Column = (props) => {
	return <div className="column">{props.venues.map((venue, key) => {return <Venue venue={venue} key={key} />})}</div>
}

const Venue = (props) => {
	const {venue} = props
	return (
		<div className="venue">
			<a rel="noopener noreferrer" target="_blank" href={venue.target}>{venue.title}</a>
		</div>
	)
}

const Title = () => {
	return <h1>Confian en nosotros,<br /> confiamos en ellos</h1>
}
export default Venues;