import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

class Card extends Component {

	render() {

		const { index, data, modifiers = '', isVisible } = this.props
		const rowOrder = (index % 2 === 0) ? '' : 'card--row-reverse'
		const visibility = isVisible === true || (typeof isVisible === 'undefined') ? 'isVisible' : 'isHidden'
		const classes = ['card', visibility, rowOrder, modifiers].join(' ')

		return(
			<div className={classes}>
				<div className="column">
					<img alt="" className="" src={`./images/sections/${data.image}`} />
				</div>
				<div className="column text">
					{data.header &&
						<div className="card__header">{data.header}</div>
					}
					<div className="card__title">{data.title}</div>
					{data.content &&
						<div className="card__content" dangerouslySetInnerHTML={ {__html: data.content} } />
					}
					{data.link &&
						<NavLink className="button" exact to={data.link.target}>{data.link.title}</NavLink>
					}
				</div>
			</div>
		)

	}
}

export default Card;

