import React, { Component } from 'react';

class Container extends Component {

	render() {

		const { background, theme = '' } = this.props;

		const style = {
			backgroundImage: (background) ? `url(./images/backgrounds/${background}@2x.jpg)` : ''
		};

		return(
			<div className={`container ${theme}`} style={style}>
				{this.props.children}
			</div>
		)

	}
}

export default Container;

