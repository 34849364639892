import React, { Component } from 'react';

import Container from '../components/Container.js'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Card from '../components/Card.js'
import Hero from '../components/Hero.js'
import Slideshow from '../components/Slideshow.js'

import { MyContext } from '../Context'

class Events extends Component {

	render() {

		return (
			<MyContext.Consumer>
				{(context) => (
					<React.Fragment>
						<Container background="events">
							<Header context={context} theme="dark" />
							<Hero data={context.translate('events.hero')}/>
						</Container>
						
						<Container>

							<div className="events">
								<div className="row">
								{
									context.translate('events.categories').map((category, key) => {
										return <Category category={category} key={key} />
									})
								}
								</div>
							</div>

						</Container>
						<Slideshow />
						<Container>
							<Card modifiers="card--no-margin" data={context.translate('our-menu.quote')} />
						</Container>
						<Footer context={context} />
					</React.Fragment>
				)}
			</MyContext.Consumer>
		)
	}
}

const Category = (props) => {

	const { category } = props;

	return (
		<div className="category column">
			<img className="category__image" alt="" src={`./images/sections${category.image}`}/>
			<div className="category__title" dangerouslySetInnerHTML={{__html: category.title}}></div>
			<div className="category__description">{category.description}</div>
		</div>
	)
}

export default Events;