import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import ReactSVG from 'react-svg'

//import Location from '../components/Location.js'

import { MyContext } from '../Context'

class Header extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			isToggleOn: false
		};
		this.handleClick = this.handleClick.bind(this);

	}

	handleClick() {
		this.setState(prevState => ({
			isToggleOn: !prevState.isToggleOn
		}));

		this.props.context.state.menuOpened = this.state.isToggleOn
	}

	render() {
		return(
			<header>
				{this.state.isToggleOn ? <MenuOpened handleClick={this.handleClick} isToggleOn={this.state.isToggleOn} /> : <MenuClosed theme={this.props.theme} handleClick={this.handleClick} isToggleOn={this.state.isToggleOn} />} 
			</header>
		)
	}
}

const MenuClosed = ({theme = 'dark', handleClick, isToggleOn}) => {

	return (
		<div className="menu-closed">
			<div className="open-menu-icon" onClick={handleClick}>

				<span className="text">MENU</span>
				<ReactSVG path={`./images/header/menu-open-${theme}.svg`} svgStyle={{ width: 20, height: 20 }} />

			</div>
			<NavLink exact to="/"><img alt="" className="logo-header" width="140" height="50" src={`./images/logo/logo-${theme}@2x.png`} /></NavLink>
		</div>
	)
}

const MenuOpened = ({handleClick, isToggleOn}) => {

	return(
		<MyContext.Consumer>
			{(context) => (

				<div className="menu-opened">
					<div className="right">
						<div className="close-menu-icon" onClick={handleClick}>
							<ReactSVG path="./images/header/menu-close.svg" svgStyle={{ width: 20, height: 20 }} />
						</div>

						<div className="language-selector">
							<span className={(context.state.language) === 'english' ? 'active' : ''} onClick={context.changeLanguage} data-language="english">EN</span>
							<span className={(context.state.language) === 'spanish' ? 'active' : ''} onClick={context.changeLanguage} data-language="spanish">ES</span>
						</div>
						<ul>
							{context.translate('header.links').map(function(elem, index) {
								return <li key={index}><NavLink exact to={elem.link}>{elem.label}</NavLink></li>
							})}

							<li>
								<div className="social-media">
									<a href={context.translate('social.facebook')} rel="noopener noreferrer" target="_blank">
										<img alt="" src="./images/social-networks/fb-icon.svg" height="18" align="absmiddle" />
									</a>
									<a href={context.translate('social.instagram')} rel="noopener noreferrer" target="_blank">
										<img alt="" src="./images/social-networks/ig-icon.svg" height="18" align="absmiddle" />
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>

			)}
		</MyContext.Consumer>
	)
}

export default Header;